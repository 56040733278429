import { Autocomplete, Button, Card, CardHeader, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { TbReport, } from "react-icons/tb";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedReport } from "../../../../app/slices/reportsSlice";
import { useState } from "react";

const ReportsHeader = () => {
  const dispatch = useDispatch()

  const reportTypes = useSelector((state) => state.reports.reportTypes)

  useEffect(() => {
    if (reportTypes[0]?.isSelected) {
      setReportType('payments')
    } else if (reportTypes[1]?.isSelected) {
      setReportType('operation')
    } else if (reportTypes[2]?.isSelected) {
      setReportType('spending')
    } else {
      setReportType(null)
    }
  }, [reportTypes])


  const [reportType, setReportType] = useState(null)

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            التقارير
          </Typography>
        }
        avatar={<TbReport size={20} />}
        action={
          <Stack direction='row' spacing={2}>
            <Autocomplete
              sx={{ width: '25vh' }}
              options={reportTypes}
              getOptionLabel={(option) => option.type}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField fullWidth required {...params} label="اختر نوع التقرير" />
              )}
              onChange={(event, value) => {
                setReportType(value ? value.name : null);
              }}
              value={
                reportTypes.filter((x) => x.name === reportType)[0]
                  ? reportTypes.filter(
                    (x) => x.name === reportType
                  )[0]
                  : null
              }
            />
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<BsSearch />}
              onClick={() =>
                dispatch(setSelectedReport(reportType))
              }
            >
              بحث
            </Button>
          </Stack>
        }
      />
    </Card>
  );
};

export default ReportsHeader;
