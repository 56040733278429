import axios from "axios";
import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAll(data) {
        let params = "";
        if (data) {
            Object.keys(data).forEach(key => {
                params += `${key}=${data[key]}&`;
            });
        }
        return api.get(`/api/v1/user?is_active=all&${params}`);
    },
    getAllList() {
        return api.get(`/api/v1/user/list`);
    },
    getRepAndPos(data) {
        let params = "";
        if (data) {
            Object.keys(data).forEach(key => {
                params += `${key}=${data[key]}&`;
            });
        }
        return api.post(`/api/v1/user/rep-or-pos?${params}`);
    },
    login(data) {
        return api.post(`/api/v1/login`, data);
    },
    logout() {
        return api.post(`/api/v1/logout`);
    },
    getById(id) {
        return api.get(`/api/v1/user/${id}`);
    },
    update(id, data) {
        return api.put(`/api/v1/user/${id}`, data);
    },
    create(data) {
        return api.post(`/api/v1/user`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    delete(id) {
        return api.delete(`/api/v1/user/${id}`);
    },
    userActivation(data) {
        return api.post(`/api/v1/user/activation/`, data);
    },
    WalletActivation(data) {
        return api.post(`/api/v1/user/wallet-activation/`, data);
    },
    userInfo() {
        return api.get(`/api/v1/user/info`);
    },
    changePassword(data) {
        return api.post(`/api/v1/user/self-change-password`, data);
    },
    getIp() {
        return axios.get(`https://api.hostip.info/get_html.php`);
    },
    resetPasswordByAdmin(data) {
        return api.post(`/api/v1/user/change-password`, data);
    },
    loginHistory(userId) {
        return api.get(`/api/v1/user/login-history?user_id=${userId}`);
    },
}