import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { SelectPatient } from "../../../components/Selectors/SelectPatient";
import {
  setDialog,
  resetForm,
  Payment
} from "../../../app/slices/paymentsSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentsForm = () => {
  const _OperationPayments = useSelector((state) => state.payment.payment);
  const _SearchBody = useSelector((state) => state.payment.searchBody);
  const _Patient = useSelector((state) => state.patient.patients.data);

  const isAdd = _OperationPayments.form.id ? false : true;
  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: _OperationPayments.form,
  });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(Payment.create(data));
    } else {
      dispatch(Payment.update(data.id, data, _SearchBody));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);


  const handlDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_OperationPayments.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="patient_id"
                control={control}
                render={({ field }) => (
                  <SelectPatient
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("patient_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Patient?.filter((x) => x.id === field.value)[0]
                        ? _Patient?.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                    search={watch('patient_name')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="operation_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم الجلسة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='amount'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='المبلغ'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('amount', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{ ...params.inputProps, placeholder: "يوم/شهر/سنة" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الوصف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentsForm;
