import { Grid } from "@mui/material";
import React from "react";
import PaymentsTable from "./home/table";
import PaymentHeader from "./home/header";
const Payments = () => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaymentHeader />
      </Grid>
      <Grid item xs={12}>
        <PaymentsTable />
      </Grid>
    </Grid>
  );
};

export default Payments;
