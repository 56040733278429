import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/appointment?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/appointment/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/appointment/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/appointment`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/appointment/${id}`);
  },
}