import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const sectionsApi = factory.get("section");
const initialState = {
  loading: false,
  searchBody: {},
  section: {
    dialog: false,
    form: {
      id: null,
      name: "",
    },
  },
  sections: {
    data: [],
    total: 0,
  },
  sectionsList: {
    data: [],
    total: 0,
  },
}

export const sectionsSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.section.dialog = !state.section.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.sections.data = payload.data.data
      state.sections.total = payload.data.total
    },
    setDatalist: (state, {
      payload
    }) => {
      state.sectionsList.data = payload.data.data
      state.sectionsList.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.section.form.id = payload.id
      state.section.form.name = payload.name
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.section.form = initialState.section.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist
} = sectionsSlice.actions

export default sectionsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('section'));
    const res = await sectionsApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('section'));

  } catch (err) {
    dispatch(setLoading('section'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('section'));
    const res = await sectionsApi.getAllList(params)
    dispatch(setDatalist(res));
    dispatch(setLoading('section'));
  } catch (err) {
    dispatch(setLoading('section'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await sectionsApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('section'));
    const res = await sectionsApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('section'));
  } catch (err) {
    dispatch(setLoading('section'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await sectionsApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await sectionsApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Section = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList
}