import React, { forwardRef, useRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { Box } from "@mui/system";
import { useEffect } from "react";
import Logo from "../../../assets/Logo";
import { useDispatch, useSelector } from "react-redux";
import { setReceiptDialog } from "../../../app/slices/operationSlice";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenerateReceipt({ data = {}, print }) {
  const _Operation = useSelector((state) => state.operation.receipt);

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
    body {
      direction: rtl; 
      }
      @page { size: auto;  margin: 0mm; }
      `,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // handlePrint();
  }, []);

  const handleDialog = () => {
    dispatch(setReceiptDialog(null));
  };

  return (
    <Dialog
      open={_Operation.dialog}
      TransitionComponent={Transition}
      keepMounted
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"وصل استلام"}
        </Typography>
        <div>
          <Button
            onClick={() => {
              handlePrint();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            طباعة
          </Button>
          <div ref={printRef} style={{ padding: "15px" }}>
            <Box
              sx={{
                position: "relative",
                height: "180mm",
                width: "80mm ",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} display={"flex"} alignItems={"center"}>
                  <Typography variant="h4">وصل استلام</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Logo vertical style={{ width: "150px", float: "left" }} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={7.5} xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>اسم المراجع:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.patient_name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={4.5} xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>التاريخ:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {dayjs(_Operation.data?.date).format("YYYY-MM-DD")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={7.5} xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>رقم الهاتف:</Typography>
                    <Stack direction="column">
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        {`${_Operation.data?.patient_mobile_1}`}
                      </Typography>
                      {_Operation.data?.patient_mobile_2 && (
                        <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                          {_Operation.data?.patient_mobile_2
                            ? _Operation.data?.patient_mobile_2
                            : ""}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={4.5} xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>رقم الجلسة:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.id}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={7.5} xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>نوع الجسلة:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.case_type_name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={4.5} xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>اسم القسم:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.section_name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>المبلغ الكلي:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.amount} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>الخصم:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.discount} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>المبلغ المدفوع:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.paid} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={0.3} alignItems={"center"}>
                    <Typography fontSize={11}>المبلغ المتبقي:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.remaining} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 6 }}>
                  {_Operation.data?.description && (
                    <Stack
                      direction={"row"}
                      spacing={0.3}
                      alignItems={"center"}
                    >
                      <Typography fontSize={11}>الوصف :</Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        {_Operation.data?.description}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography fontSize={11}>العنوان:</Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        العراق - بغداد - حي اور شارع ٦٠٠ بداية الشارع مقابل
                        الاستدارة الثالثة عيادات وصالون لوگانو
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography fontSize={11}>هاتف:</Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        07729006333 - 07829009003
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
