import { Grid } from "@mui/material";
import React from "react";
import AppointmentHeader from "./home/header";
import AppointmentTable from "./home/table";

const Appointment = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppointmentHeader />
      </Grid>
      <Grid item xs={12}>
        <AppointmentTable />
      </Grid>
    </Grid>
  );
};

export default Appointment;
