import { Dialog, DialogContent, Grid, Slide } from "@mui/material";
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableDialog } from "../../../app/slices/stockMovementSlice";
import StockMovementsHeader from "./home/header";
import StockMovementTable from "./home/table";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StockMovement = () => {
  const _StockMovements = useSelector((state) => state.stockMovement.stockMovements);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setTableDialog());
  };

  return (
    <Dialog
      open={_StockMovements.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      maxWidth='lg'
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StockMovementsHeader />
          </Grid>
          <Grid item xs={12}>
            <StockMovementTable />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StockMovement;
