import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StockMovement, setSearchBody,
} from "../../../../../app/slices/stockMovementSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { SelectUser } from "../../../../../components/Selectors/SelectUser";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";

const StockMovementTable = () => {
  const [search, setSearch] = useState({
    search: '',
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
    user_id: null,
    is_in: ""
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');

  const dispatch = useDispatch();

  const _StockMovement = useSelector((state) => state.stockMovement.stockMovements);
  const _SearchBody = useSelector((state) => state.stockMovement.searchBody);
  const loading = useSelector((state) => state.stockMovement.loading);
  const _User = useSelector((state) => state.user.usersList.data);
  const _StockSearchBody = useSelector((state) => state.stock.searchBody);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(StockMovement.deleteById(dialogData.id, _StockMovement.stock_id, _SearchBody, _StockSearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const stock_types = [
    { name: 'الكل', key: '' },
    { name: 'اضافة للمخزن', key: true },
    { name: 'سحب من المخزن', key: 'false' },
  ]

  const columns = [
    {
      field: "id",
      headerName: "رقم الحركة",
      flex: 1,
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 1,
    },
    {
      field: "created_by_name",
      headerName: "عن طريق",
      flex: 1,
    },
    {
      field: "user_name",
      headerName: "اسم المستلم",
      flex: 1,
    },
    {
      field: "description",
      headerName: "الوصف",
      flex: 1,
    },
    {
      field: "is_in",
      headerName: "نوع الحركة",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.is_in ? 'green' : 'error'}>
          {params.row.is_in ? 'اضافة للمخزن' : 'سحب من المخزن'}
        </Typography>
      )
    },
    {
      field: "date",
      headerName: "تاريخ الحركة",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {dayjs(params.row.date).format("YYYY-MM-DD hh:mm a")}
        </Typography>
      )
    },
    {
      field: "created_at ",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {dayjs(params.row.created_at).format("YYYY-MM-DD hh:mm a")}
        </Typography>
      )
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
      if (_StockMovement.stock_id) {
        dispatch(StockMovement.getAll(_StockMovement.stock_id, _SearchBody));
      }
  }, [dispatch,_SearchBody, _StockMovement.stock_id]);

  useEffect(() => {
    dispatch(setSearchBody({
      search: search.search,
      is_in: search.is_in,
      start_date: search.start_date,
      end_date: search.end_date,
      user_id: search.user_id ?? "",
      skip: pageSize * currentPage,
      take: pageSize
    }))
  }, [dispatch,search, pageSize, currentPage]);


  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف رقم الحركة: "
            name={dialogData.id}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={3}>
            <SelectUser
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  user_id: newValue ? newValue.id : null
                });
              }}
              value={
                _User?.filter((x) => x.id === search.user_id)[0]
                  ? _User?.filter(
                    (x) => x.id === search.user_id
                  )[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={stock_types}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="حالة المنتج" />
              )}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  is_in: newValue.key
                });
              }}
              value={
                stock_types.filter((x) => x.key === search.is_in)[0]
                  ? stock_types?.filter(
                    (x) => x.key === search.is_in
                  )[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: '',
                    is_in: '',
                    start_date: dayjs().format("YYYY-MM-01"),
                    end_date: dayjs().format("YYYY-MM-DD"),
                    user_id: null
                  });
                  setPageSize(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "68vh", width: "100%" }}>
              {_StockMovement.data ? (
                <DataGrid
                  rows={_StockMovement.data}
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={_StockMovement.total}
                  loading={loading}
                  rowHeight={45}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    setCurrentPage(newPage)
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StockMovementTable;
