import {
    lazy
} from 'react';
import { Navigate } from 'react-router';

// project imports
import MinimalLayout from '../layout/MinimalLayout';
import Loadable from './../components/Loadable';

// auth routing
const AuthLogin = Loadable(lazy(() => import('../views/login')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: < MinimalLayout />,
    children: [{
        path: '/login',
        element: < AuthLogin />
    }, {
        path: "/",
        element: <Navigate to="/login" />
    }]
};

export default AuthenticationRoutes;