import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
import { setStockMovementDialog } from "./stockMovementSlice";
const stockApi = factory.get("stock");
const initialState = {
  loading: false,
  searchBody: {},
  stock: {
    dialog: false,
    form: {
      id: null,
      name: "",
      description: "",
      count: "",
      note: "",
    },
  },
  stocks: {
    data: [],
    total: 0,
  },
  sectionsList: {
    data: [],
    total: 0,
  },
}

export const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.stock.dialog = !state.stock.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.stocks.data = payload.data.data
      state.stocks.total = payload.data.total
    },
    setDatalist: (state, {
      payload
    }) => {
      state.sectionsList.data = payload.data.data
      state.sectionsList.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.stock.form.id = payload.id
      state.stock.form.count = payload.count
    },
    setByIdForUpdate: (state, {
      payload
    }) => {
      state.stock.form.id = payload.id
      state.stock.form.name = payload.name
      state.stock.form.description = payload.description
      state.stock.form.count = payload.count
      state.stock.form.note = payload.note
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.stock.form = initialState.stock.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
  setByIdForUpdate
} = stockSlice.actions

export default stockSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('stock'));
    const res = await stockApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('stock'));

  } catch (err) {
    dispatch(setLoading('stock'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('stock'));
    const res = await stockApi.getAllList(params)
    dispatch(setDatalist(res));
    dispatch(setLoading('stock'));
  } catch (err) {
    dispatch(setLoading('stock'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await stockApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('stock'));
    const res = await stockApi.getById(id)
    dispatch(setByIdForUpdate(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('stock'));
  } catch (err) {
    dispatch(setLoading('stock'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('stock'));
    const res = await stockApi.getById(id)
    dispatch(setById(res.data.data[0]));
    dispatch(setStockMovementDialog())
    dispatch(setLoading('stock'));
  } catch (err) {
    dispatch(setLoading('stock'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await stockApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await stockApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Stock = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  getByIdForUpdate
}