import { Card, Typography, Grid, Stack, Box, CircularProgress, TextField, Button, InputAdornment, IconButton, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../app/slices/userSlice"
import { HiOutlineMail } from "react-icons/hi"
import { BiDesktop} from "react-icons/bi"
import { AiFillEye, AiFillEyeInvisible, AiOutlineUser } from "react-icons/ai"
import ConfirmationDialog from "../../../helpers/ConfirmationDialog";
import { Controller, useForm } from "react-hook-form";
import { showNotification } from "../../../app/slices/notificationSlice";

const Profile = () => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');

  const passwordForm = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  const { control, reset, handleSubmit } = useForm({
    defaultValues: passwordForm,
  });

  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmPassword) dispatch(showNotification({
      message: "كلمة المرور الجديد وتاكيدها غير متطابقتين",
      type: "error"
    }));
    else {
      setDialogData(data)
      setConfirmOpen(true);
    }
  };

  const handleConfirmChangePassword = () => {
    dispatch(User.updatePassowrd({
      // user_id: userInfo.id,
      old_password: dialogData.oldPassword,
      new_password: dialogData.confirmPassword
    })).then((x) => {
      setLoading(false);
      reset({ ...passwordForm })
    }).catch((error) => {
      setLoading(false);
      setDialogData({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      })
    })
    setConfirmOpen(false);
  };

  const handleCancelChangePassword = () => {
    setConfirmOpen(false);
  };

  return (
    <Box>
      {userInfo ? (
        <Grid container spacing={2}>
          <Grid item xs={12} display={'flex'} justifyContent="center" alignItems='center'>
            <Card sx={{ p: 2, background: "linear-gradient(#864721, #c62828)", height: 200, width: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Stack direction='column' spacing={2} alignItems='start' justifyContent='start' >
                <Stack direction='row' spacing={1} alignItems='start' justifyContent='center'>
                  <AiOutlineUser color="white" size={20} />
                  <Typography variant="h4" color='white'>الاسم: </Typography>
                  <Typography variant="h4" color='white'>{userInfo?.user_name}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='start' justifyContent='center'>
                  <HiOutlineMail color="white" size={20} />
                  <Typography variant="h4" color='white'>البريد الالكتروني:</Typography>
                  <Typography variant="h4" color='white'>{userInfo?.email}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='start' justifyContent='center'>
                  <BiDesktop color="white" size={20} />
                  <Typography variant="h4" color='white'>العنوان الوظيفي : {userInfo?.job_title}</Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} >
            <Card sx={{ height: 400, width: 400, borderRadius: 3, border: '#e45d1d', borderBottomStyle: 'solid', borderTopStyle: 'solid'}}>
              {loading ? (<LinearProgress />) : null}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={{ height: 400, p: 2 }} direction='column' spacing={2} alignItems='end' justifyContent='center'>
                  <Controller
                    name="oldPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type={showOldPassword ? "text" : "password"}
                        {...field}
                        required
                        fullWidth
                        value={field.value}
                        label="كلمة المرور القديمة"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowOldPassword(!showOldPassword)}
                              >
                                {showOldPassword ? <AiFillEye size={22} /> : <AiFillEyeInvisible size={22} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="newPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type={showNewPassword ? "text" : "password"}
                        {...field}
                        required
                        fullWidth
                        label="كلمة المرور الجديدة"
                        value={field.value}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                              >
                                {showNewPassword ? <AiFillEye size={22} /> : <AiFillEyeInvisible size={22} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type={showConfirmPassword ? "text" : "password"}
                        {...field}
                        required
                        fullWidth
                        value={field.value}
                        label="تاكيد كلمة المرور الجديدة"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              >
                                {showConfirmPassword ? <AiFillEye size={22} /> : <AiFillEyeInvisible size={22} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Box sx={{ paddingTop: 2 }}>
                    <Button type="submit" color="primary" variant='contained'>حفظ</Button>
                  </Box>
                </Stack>
              </form>
              <ConfirmationDialog
                open={openConfirm}
                title="تاكيد"
                message="هل انت متاكد من كلمة المرور !"
                confirmText="تأكيد"
                cancelText="الغاء"
                onConfirm={() => handleConfirmChangePassword()}
                onCancel={handleCancelChangePassword}
              />
            </Card>
          </Grid>
        </Grid>
      ) :
        <Stack direction='column' spacing={4} alignItems='center' justifyContent='center' sx={{ height: 300 }}>
          <CircularProgress />
        </Stack>
      }
    </Box>
  );
};

export default Profile;
