import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Appointment, setSearchBody,
} from "../../../../app/slices/appointmentSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import dayjs from "dayjs";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { setOperationDialog } from "../../../../app/slices/operationSlice";
import OperationForm from "../../../operation/form";
import { GiOfficeChair } from "react-icons/gi";

const AppointmentTable = () => {

  const _Appointment = useSelector((state) => state.appointment.appointments);
  const _SearchBody = useSelector((state) => state.appointment.searchBody);
  const loading = useSelector((state) => state.appointment.loading);
  const _Operation = useSelector((state) => state.operation.operation);

  const [search, setSearch] = useState({
    search: "",
    start_date: dayjs().format("YYYY-MM-DD 00:00:00"),
    end_date: dayjs().format("YYYY-MM-DD 23:59:59")
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');
  const [appointmentId, setAppointmentId] = useState('');

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(Appointment.deleteById(dialogData.id, _SearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: 'id',
      headerName: "رقم الموعد",
      flex: 1,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "case_type_name",
      headerName: "اسم الحالة",
      flex: 1,
    },
    {
      field: "status",
      headerName: "حالة الموعد",
      flex: 1,
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 2,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {dayjs(params.row.date).format("YYYY-MM-DD / hh:mm: a")}
          </>
        )
      }
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="اضافة جلسة">
              <IconButton
                onClick={() => {
                  dispatch(setOperationDialog());
                  setAppointmentId(params.row.id)
                }}
              >
                <GiOfficeChair />
              </IconButton>
            </Tooltip>
            <Tooltip title="تعديل">
              <IconButton
                color="primary"
                onClick={() => dispatch(Appointment.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Tooltip>
            <Tooltip title="حذف">
              <IconButton
                color="error"
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Appointment.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch,_SearchBody]);

  useEffect(() => {
    dispatch(setSearchBody({
      search: search.search,
      start_date: search.start_date,
      end_date: search.end_date,
      skip: pageSize * currentPage,
      take: pageSize
    }))
  }, [dispatch,search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_Operation.dialog && <OperationForm appointmentId={appointmentId} />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.patient_name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value
                });
              }}
              value={search.search}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    start_date: dayjs().format("YYYY-MM-DD 00:00:00"),
                    end_date: dayjs().format("YYYY-MM-DD 23:59:59")
                  });
                  setPageSize(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Appointment.data ? (
                <DataGrid
                  rows={_Appointment.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Appointment.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    currentPage(newPage)
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentTable;
