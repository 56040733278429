import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { GiExpense } from "react-icons/gi";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../app/slices/expenseSlice";
import { setOpenDialog } from "../../../../app/slices/expenseTypesSlice";
import { IoIosAddCircleOutline } from "react-icons/io";
import ExpenseForm from "../../from";
import ExpenseTypes from "../../expenseTypes";

const ExpenseHeader = () => {
  const _Expense = useSelector((state) => state.expense);
  const _ExpenseTypes = useSelector((state) => state.expenseTypes);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المصاريف
          </Typography>
        }
        avatar={<GiExpense size={20} />}
        action={
          <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setOpenDialog())}
            >
              أنواع المصاريف
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة
            </Button>
          </Stack>
        }
      />
      {_Expense.expense.dialog ? <ExpenseForm /> : null}
      {_ExpenseTypes.dialog ? <ExpenseTypes /> : null}
    </Card>
  );
};

export default ExpenseHeader;
