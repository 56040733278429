import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, CircularProgress } from "@mui/material";
import { useEffect, forwardRef, Fragment } from "react";
import { styled } from "@mui/system";
import { User } from "../../app/slices/userSlice";

export const SelectUser = forwardRef((props, ref) => {

  const user = useSelector((state) => state.user.usersList);
  const loading = useSelector((state) => state.user.user.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(User.getAllList({
      take: 100
    }));
  }, [dispatch]);

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    borderBottom: "1px solid #e0e0e0",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });


  return (
    <Autocomplete
      ref={ref}
      options={user.data || []}
      {...props}
      getOptionLabel={(option) => option.full_name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          required={props.requird}
          {...params}
          label="اسم المستخدم"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderGroup={(params, key) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
});
