import { Grid } from "@mui/material";
import React from "react";
import SectionTable from "./home/table";
import SectionHeader from "./home/header";

const Section = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionHeader />
      </Grid>
      <Grid item xs={12}>
        <SectionTable />
      </Grid>
    </Grid>
  );
};

export default Section;
