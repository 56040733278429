import {
  lazy
} from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
// import Users from '../views/users';
import Loadable from './../components/Loadable';
import Provinces from '../views/management/provinces/province';
import Section from '../views/sections';
import Patient from '../views/patient';
import CaseType from '../views/caseType';
import Appointment from '../views/appointment';
import Expense from '../views/expense';
import Operation from '../views/operation';
import Stock from '../views/stock/stockDetails';
import Payments from '../views/payments';
import Reports from '../views/reports';
import Profile from '../views/users/profile';

// dashboard routing
const Home = Loadable(lazy(() => import('../views/home')));
const Users = Loadable(lazy(() => import('../views/users')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/app',
  element: < MainLayout />,
  children: [
    {
      path: '/app',
      element: < Home />
    },
    {
      path: '/app/users',
      element: < Users />
    },
    {
      path: '/app/user/Profile',
      element: < Profile />
    },
    {
      path: '/app/patients',
      element: < Patient />
    },
    {
      path: '/app/sections',
      element: < Section />
    },
    {
      path: '/app/case_type',
      element: < CaseType />
    },
    {
      path: '/app/operation',
      element: < Operation />
    },
    {
      path: '/app/all_appointments',
      element: < Appointment />
    },
    {
      path: '/app/payments',
      element: < Payments />
    },
    {
      path: '/app/stock',
      element: < Stock />,
    },
    {
      path: '/app/expense',
      element: < Expense />
    },
    {
      path: '/app/reports',
      element: < Reports />
    },
    {
      path: '/app/management',
      children: [
        {
          path: '/app/management/provinces',
          element: < Provinces />
        }
      ]
    },
  ]
};

export default MainRoutes;