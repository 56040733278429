const Logo = ({vertical = false , style={}}) => (
    <img
      alt="Logo"
      src={
        vertical
          ? "/images/Logo/lugano-01.png"
          : "/images/Logo/lugano-01.png"
      }
      style = {style}
  
      
    />
  );
  
  export default Logo;