import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const expenseApi = factory.get("expense");
const initialState = {
  searchBody: {},
  expense: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      spending_type_id: null,
      amount: "",
      date: dayjs().format("YYYY-MM-DD"),
      note: ""
    },
  },
  expenses: {
    loading: false,
    data: [],
    total: 0,
  },
}

export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.expense.dialog = !state.expense.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.expenses.data = payload.data.data
      state.expenses.total = payload.data.total
      state.expenses.total_amount = payload.data.total_amount
    },
    setById: (state, {
      payload
    }) => {
      state.expense.form.id = payload.id
      state.expense.form.spending_type_id = payload.spending_type_id
      state.expense.form.amount = payload.amount
      state.expense.form.date = payload.date
      state.expense.form.note = payload.note
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.expense.form = initialState.expense.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody
} = expenseSlice.actions

export default expenseSlice.reducer;

//axios
const getAllExpense = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('expense'));
    const res = await expenseApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('expense'));

  } catch (err) {
    dispatch(setLoading('expense'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await expenseApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAllExpense(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('expense'));
    const res = await expenseApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('expense'));
  } catch (err) {
    dispatch(setLoading('expense'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await expenseApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAllExpense())
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await expenseApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAllExpense(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Expense = {
  getAllExpense,
  deleteById,
  getById,
  create,
  update
}