import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const appointmentApi = factory.get("appointment");
const initialState = {
  loading: false,
  searchBody: {},
  appointment: {
    dialog: false,
    form: {
      id: "",
      date: dayjs().format('YYYY-MM-DD HH:mm'),
      patient_id: null,
      case_type_id: null,
      status: 'تم الحجز',
      note: "",
    },
  },
  appointments: {
    data: [],
    total: 0,
  },
}

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.appointment.dialog = !state.appointment.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.appointments.data = payload.data.data
      state.appointments.total = payload.data.total
    },
    setDatalist: (state, {
      payload
    }) => {
      state.sectionsList.data = payload.data.data
      state.sectionsList.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.appointment.form.id = payload.id
      state.appointment.form.case_type_id = payload.case_type_id
      state.appointment.form.patient_id = payload.patient_id
      state.appointment.form.patient_name = payload.patient_name
      state.appointment.form.date = payload.date
      state.appointment.form.status = payload.status
      state.appointment.form.note = payload.note
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.appointment.form = initialState.appointment.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist
} = appointmentSlice.actions

export default appointmentSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('appointment'));
    const res = await appointmentApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('appointment'));

  } catch (err) {
    dispatch(setLoading('appointment'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('appointment'));
    const res = await appointmentApi.getAllList(params)
    dispatch(setDatalist(res));
    dispatch(setLoading('appointment'));
  } catch (err) {
    dispatch(setLoading('appointment'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await appointmentApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('appointment'));
    const res = await appointmentApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('appointment'));
  } catch (err) {
    dispatch(setLoading('appointment'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await appointmentApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await appointmentApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Appointment = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList
}