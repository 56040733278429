import axios from "axios";
const authInterceptor = (config) => {
  const token = localStorage.getItem("token");
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] = "application/json";
  return config;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API || 'https://api-test-lugano.otech.team/',
});

api.interceptors.request.use(authInterceptor);

api.interceptors.response.use((response) => {
  return response
},
  (error) => {
    // eslint-disable-next-line default-case
    switch (error.response.status) {
      case 401:
        localStorage.removeItem("token")
        window.location.href = "/login";
        break;
      // case 0:
        // localStorage.removeItem("token")
        // window.location.href = "/login";
        // break;
    }

    return Promise.reject(error);
  }
)
export default api;