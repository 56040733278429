import { Grid } from "@mui/material";
import React from "react";
import CaseTypeHeader from "./home/header";
import CaseTypeTable from "./home/table";

const CaseType = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CaseTypeHeader />
      </Grid>
      <Grid item xs={12}>
        <CaseTypeTable />
      </Grid>
    </Grid>
  );
};

export default CaseType;
