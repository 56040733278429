import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CaseType, setSearchBody,
} from "../../../../app/slices/caseTypeSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";

const CaseTypeTable = () => {

  const _CaseType = useSelector((state) => state.caseType.caseTypes);
  const _SearchBody = useSelector((state) => state.caseType.searchBody);
  const loading = useSelector((state) => state.caseType.loading);

  const [search, setSearch] = useState({
    search: "",
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(CaseType.deleteById(dialogData.id, _SearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "price",
      headerName: "السعر",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.price?.toLocaleString()}</>
      )
    },
    {
      field: "section_name",
      headerName: "القسم",
      flex: 1,
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 2,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(CaseType.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>

            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(CaseType.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch,_SearchBody]);

  useEffect(() => {
    dispatch(setSearchBody({
      search: search.search,
      city_id: search.city_id ?? "",
      skip: pageSize * currentPage,
      take: pageSize
    }))
  }, [dispatch,search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value
                });
              }}
              value={search.search}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    city_id: null
                  });
                  setPageSize(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_CaseType.data ? (
                <DataGrid
                  rows={_CaseType.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_CaseType.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    setCurrentPage(newPage)
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CaseTypeTable;
