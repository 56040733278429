import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../app/slices/caseTypeSlice";
import { MdAirlineSeatFlatAngled } from "react-icons/md";
import CaseTypeForm from "../../form";

const CaseTypeHeader = () => {
  const _CaseType = useSelector((state) => state.caseType.caseType);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            انواع الجلسات
          </Typography>
        }
        avatar={<MdAirlineSeatFlatAngled size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_CaseType.dialog ? <CaseTypeForm /> : null}
    </Card>
  );
};

export default CaseTypeHeader;
