import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";

import SideBar from "./Sidebar";
import AppBar from "./AppBar";
import {useSelector } from "react-redux";

// project imports
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Header from './Header';
// import Sidebar from './Sidebar';
// import navigation from 'menu-items';

// assets
// import { IconChevronRight } from '@tabler/icons';

// styles
// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     ...theme.typography.mainContent,
//     ...(!open && {
//       borderBottomLeftRadius: 0,
//       borderBottomRightRadius: 0,
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//       }),
//       [theme.breakpoints.up("md")]: {
//         marginRight: -(260 - 20),
//         // width: `calc(100% - ${260}px)`,
//         width: `calc(100% - ${200}px)`,
//       },
//       [theme.breakpoints.down("md")]: {
//         marginLeft: "20px",
//         width: `calc(100% - ${260}px)`,
//         padding: "16px",
//       },
//       [theme.breakpoints.down("sm")]: {
//         marginLeft: "10px",
//         width: `calc(100% - ${260}px)`,
//         padding: "16px",
//         marginRight: "10px",
//       },
//     }),
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//       borderBottomLeftRadius: 0,
//       borderBottomRightRadius: 0,
//       width: `calc(100% - ${260}px)`,
//       [theme.breakpoints.down("md")]: {
//         marginLeft: "20px",
//       },
//       [theme.breakpoints.down("sm")]: {
//         marginLeft: "10px",
//       },
//     }),
//   })
// );

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    backgroundColor: "#FAFAFA",
    flexGrow: 1,
    // marginTop: 100,
    width: "100%",
    minHeight: "calc(100vh - 80px)",
    // flexGrow: 1,
    padding: "10px",
    marginTop: "75px",
    // marginRight: '20px',
    borderRadius: `8px`,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -220,
        width: `calc(100% - ${220}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${220}px)`,
        padding: "25px",
      },
      [theme.breakpoints.down("sm")]: {
        width: `calc(100% - ${220}px)`,
        // padding: "40px",
        marginRight: "10px",
        marginLeft: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${220}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginLeft: -(260 - 40),
    // ...(open && {
    //   transition: theme.transitions.create("margin", {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   marginLeft: 0,
    // }),
  })
);


// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const leftDrawerOpened = useSelector((state) => state.app.is_drawer_opened);

  return (
    <Box sx={{ display: "flex" }} dir="rtl">
      <CssBaseline />
      <AppBar />
      <SideBar />
      <Main open={leftDrawerOpened}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
