import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
import { Stock } from "./stockSlice";
const stockMovementApi = factory.get("stockMovement");
const initialState = {
  loading: false,
  searchBody: {},
  stockMovement: {
    dialog: false,
    stock_id: null,
    form: {
      id: null,
      stock_id: null,
      description: "",
      count: "",
      is_in: "",
      user_id: null,
      data: null
    },
  },
  stockMovements: {
    stock_id: null,
    item_name: "",
    dialog: false,
    data: [],
    total: 0,
  },
}

export const stockMovementSlice = createSlice({
  name: "stockMovement",
  initialState,
  reducers: {
    setTableDialog: (state, {
      payload
    }) => {
      state.stockMovements.dialog = !state.stockMovements.dialog
      state.stockMovements.stock_id = payload?.id
      state.stockMovements.item_name = payload?.name
    },
    setStockMovementDialog: (state, {
      payload
    }) => {
      state.stockMovement.dialog = !state.stockMovement.dialog
      state.stockMovement.stock_id = payload
    },
    setDataTable: (state, {
      payload
    }) => {
      state.stockMovements.data = payload.data.data
      state.stockMovements.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.stockMovement.form.id = payload.id
      state.stockMovement.form.stock_id = payload.stock_id
      state.stockMovement.form.description = payload.description
      state.stockMovement.form.count = payload.count
      state.stockMovement.form.is_in = payload.is_in
      state.stockMovement.form.user_id = payload.user_id
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.stockMovement.form = initialState.stockMovement.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setTableDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setStockMovementDialog
} = stockMovementSlice.actions

export default stockMovementSlice.reducer;

//axios
const getAll = (id, params) => async (dispatch) => {
  try {
    dispatch(setLoading('stockMovement'));
    const res = await stockMovementApi.getAll(id, params)
    dispatch(setDataTable(res));
    dispatch(setLoading('stockMovement'));

  } catch (err) {
    dispatch(setLoading('stockMovement'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, stock_id, _SearchBody, stockSearchBody) => async (dispatch) => {
  try {
    await stockMovementApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(stock_id, _SearchBody))
    dispatch(Stock.getAll(stockSearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('stockMovement'));
    const res = await stockMovementApi.getById(id)
    dispatch(setById(res.data.data[0]));

    // dispatch(setStockMovementDialog())
    dispatch(setLoading('stockMovement'));
  } catch (err) {
    dispatch(setLoading('stockMovement'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data, _SearchBody, stockSearchBody) => async (dispatch) => {
  try {
    await stockMovementApi.create(data)
    dispatch(showNotification({
      message: "تم التعديل  بنجاح",
      type: "success"
    }));
    dispatch(setStockMovementDialog(null))
    dispatch(Stock.getAll(stockSearchBody))
    dispatch(getAll(data?.stock_id, _SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const StockMovement = {
  getAll,
  deleteById,
  getById,
  create,
}