import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
import { Operation } from "./operationSlice";
const paymentApi = factory.get("payment");
const initialState = {
  loading: false,
  searchBody: {},
  payment: {
    dialog: false,
    form: {
      id: "",
      amount: '',
      operation_id: null,
      patient_id: null,
      description: "",
      date: dayjs().format("YYYY-MM-DD"),
    },
  },
  payments: {
    data: [],
    total: 0,
  },
  operationPayment: {
    dialog: false,
    operation_id: null,
  },
}

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setOperationPaymentDialog: (state, {
      payload
    }) => {
      state.operationPayment.dialog = !state.operationPayment.dialog
      state.operationPayment.operation_id = payload
    },
    setDialog: (state, action) => {
      state.payment.dialog = !state.payment.dialog
    },
    setDataTable: (state, {
      payload
    }) => {
      state.payments.data = payload.data.data
      state.payments.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.payment.form.id = payload.id
      state.payment.form.amount = payload.amount
      state.payment.form.operation_id = payload.operation_id
      state.payment.form.operation_name = payload.operation_name
      state.payment.form.patient_id = payload.patient_id
      state.payment.form.patient_name = payload.patient_name
      state.payment.form.description = payload.description
      state.payment.form.date = payload.date
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.payment.form = initialState.payment.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setOperationPaymentDialog
} = paymentSlice.actions

export default paymentSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('payment'));
    const res = await paymentApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('payment'));

  } catch (err) {
    dispatch(setLoading('payment'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await paymentApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('payment'));
    const res = await paymentApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog())
    dispatch(setLoading('payment'));
  } catch (err) {
    dispatch(setLoading('payment'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await paymentApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await paymentApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const createFromOperations = (data, operation_id, operationSearchBody) => async (dispatch) => {
  try {
    await paymentApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(setDialog())
    dispatch(getAll({ operation_id: operation_id }))
    dispatch(Operation.getAll(operationSearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Payment = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  createFromOperations,
}