import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  CaseType,
} from "../../../app/slices/caseTypeSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectSection } from "../../../components/Selectors/SelectSection";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CaseTypeForm = () => {
  const _CaseType = useSelector((state) => state.caseType.caseType);
  const _SearchBody = useSelector((state) => state.caseType.searchBody);
  const _Sections = useSelector((state) => state.section.sections);

  const isAdd = _CaseType.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _CaseType.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(CaseType.create({
        name: data.name,
        section_id: data.section_id,
        note: data.note,
        discount: data.discount ? data.discount : 0,
        price: data.price
      }, _SearchBody));
    } else {
      dispatch(CaseType.update(data.id, {
        id: data.id,
        name: data.name,
        section_id: data.section_id,
        note: data.note,
        discount: data.discount ? data.discount : 0,
        price: data.price
      }, _SearchBody));
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    reset({ ..._CaseType.form });
  }, [reset,_CaseType.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);


  const handleDialog = () => {
    dispatch(setDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_CaseType.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="section_id"
                control={control}
                render={({ field }) => (
                  <SelectSection
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("section_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Sections.data?.filter((x) => x.id === field.value)[0]
                        ? _Sections.data?.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="السعر"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="discount"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الخصم"
                    fullWidth
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CaseTypeForm;
