import dayjs from "dayjs";
import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const operationApi = factory.get("operation");
const initialState = {
  loading: false,
  searchBody: {},
  operation: {
    dialog: false,
    form: {
      id: "",
      patient_id: null,
      appointment_id: null,
      case_type_id: null,
      user_id: null,
      amount: "",
      discount: "",
      paid: "",
      description: "",
      date: dayjs().format("YYYY-MM-DD"),
    },
  },
  operations: {
    data: [],
    total: 0,
  },
  receipt: {
    dialog: false,
    data: [],
    operation_id: null
  }
}

export const operationSlice = createSlice({
  name: "operation",
  initialState,
  reducers: {
    setOperationDialog: (state, action) => {
      state.operation.dialog = !state.operation.dialog
    },
    setReceiptDialog: (state, {
      payload
    }) => {
      state.receipt.dialog = !state.receipt.dialog
      state.receipt.operation_id = payload
    },
    setReceiptData: (state, {
      payload
    }) => {
      state.receipt.data = payload
    },
    setDataTable: (state, {
      payload
    }) => {
      state.operations.data = payload.data.data
      state.operations.total = payload.data.total
    },
    setDatalist: (state, {
      payload
    }) => {
      state.sectionsList.data = payload.data.data
      state.sectionsList.total = payload.data.total
    },
    setById: (state, {
      payload
    }) => {
      state.operation.form.id = payload.id
      state.operation.form.patient_id = payload.patient_id
      state.operation.form.patient_name = payload.patient_name
      state.operation.form.appointment_id = payload.appointment?.id
      state.operation.form.case_type_id = payload.case_type_id
      state.operation.form.user_id = payload.user_id
      state.operation.form.amount = payload.amount
      state.operation.form.discount = payload.discount
      state.operation.form.paid = payload.paid
      state.operation.form.description = payload.description
      state.operation.form.date = payload.date
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.operation.form = initialState.operation.form
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
  }
})

export const {
  setLoading,
  setOperationDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
  setReceiptDialog,
  setReceiptData
} = operationSlice.actions

export default operationSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('operation'));
    const res = await operationApi.getAll(params)
    dispatch(setDataTable(res));
    dispatch(setLoading('operation'));

  } catch (err) {
    dispatch(setLoading('operation'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('operation'));
    const res = await operationApi.getAllList(params)
    dispatch(setDatalist(res));
    dispatch(setLoading('operation'));
  } catch (err) {
    dispatch(setLoading('operation'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await operationApi.delete(id)
    dispatch(showNotification({
      message: "تم الحذف بنجاح",
      type: "success"
    }));
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('operation'));
    const res = await operationApi.getById(id)
    dispatch(setById(res.data.data[0]));

    dispatch(setOperationDialog())
    dispatch(setLoading('operation'));
  } catch (err) {
    dispatch(setLoading('operation'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getByIdForReceipt = (id) => async (dispatch) => {
  try {
    dispatch(setLoading('operation'));
    const res = await operationApi.getById(id)
    dispatch(setReceiptData(res.data.data[0]));
    dispatch(setReceiptDialog(null))
    dispatch(setLoading('operation'));
  } catch (err) {
    dispatch(setLoading('operation'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    const res = await operationApi.create(data)
    dispatch(showNotification({
      message: "تمت الاضافة  بنجاح",
      type: "success"
    }));
    dispatch(getByIdForReceipt(res.data.data[0]?.id))
    dispatch(setOperationDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await operationApi.update(id, data)
    dispatch(showNotification({
      message: "تم التعديل  بنجاح",
      type: "success"
    }));
    dispatch(setOperationDialog())
    dispatch(getAll(_SearchBody))
  } catch (err) {
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Operation = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  getByIdForReceipt
}