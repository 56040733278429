import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const statisticApi = factory.get("statistic");
const initialState = {
  loading: false,
  statistic: {
    data: [],
    total: 0,
  },
  statisticChart: {
    data: [],
    total: 0,
  },
}

export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setStatisticTable: (state, {
      payload
    }) => {
      state.statistic.data = payload.data.data
      state.statistic.total = payload.data.total
    },
    setStatisticChartTable: (state, {
      payload
    }) => {
      state.statisticChart.data = payload.data.data
      state.statisticChart.total = payload.data.total
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },

  }
})

export const {
  setLoading,
  setStatisticTable,
  setStatisticChartTable,
} = statisticSlice.actions

export default statisticSlice.reducer;

//axios
const getStatistic = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('statistic'));
    const res = await statisticApi.statistic(params)
    dispatch(setStatisticTable(res));
    dispatch(setLoading('statistic'));

  } catch (err) {
    dispatch(setLoading('statistic'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const getStatisticChart = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('statistic'));
    const res = await statisticApi.statisticChart(params)
    dispatch(setStatisticChartTable(res));
    dispatch(setLoading('statistic'));

  } catch (err) {
    dispatch(setLoading('statistic'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
export const Statistic = {
  getStatistic,
  getStatisticChart,
}