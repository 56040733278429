import { Dialog, DialogContent, Grid, Slide } from "@mui/material";
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import OperationPaymentHeader from "./home/header";
import OperationPaymentsTable from "./home/table";
import { setOperationPaymentDialog } from "../../../../app/slices/paymentsSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OperationPayments = () => {
  const _OperationPayment = useSelector((state) => state.payment.operationPayment);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setOperationPaymentDialog(null));
  };

  return (
    <Dialog
      open={_OperationPayment.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      maxWidth='lg'
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OperationPaymentHeader />
          </Grid>
          <Grid item xs={12}>
            <OperationPaymentsTable />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OperationPayments;
