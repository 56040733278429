import {
  createSlice
} from "@reduxjs/toolkit";
import {
  showNotification
} from "./notificationSlice";
import {
  factory
} from "../../api/apiFactory";
const reportsApi = factory.get("reports");
const initialState = {
  searchBody: {},
  loading: false,
  payments: {
    data: [],
    total: 0,
  },
  spending: {
    data: [],
    total: 0,
  },
  operation: {
    data: [],
    total: 0,
  },
  reportTypes: [
    { type: 'المدفوعات', name: 'payments', isSelected: false },
    { type: 'العمليات', name: 'operation', isSelected: false },
    { type: 'المصاريف', name: 'spending', isSelected: false },
  ]
}

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setPaymentsTable: (state, {
      payload
    }) => {
      state.payments.data = payload.data.data
      state.payments.total = payload.data.total
    },
    setSpendingsTable: (state, {
      payload
    }) => {
      state.spending.data = payload.data.data
      state.spending.total = payload.data.total
    },
    setOperationsTable: (state, {
      payload
    }) => {
      state.operation.data = payload.data.data
      state.operation.total = payload.data.total
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setSearchBody: (state, {
      payload
    }) => {
      state.searchBody = payload;
    },
    setSelectedReport: (state, {
      payload
    }) => {
      state.reportTypes = state.reportTypes.map((type) => type.name === payload ? { ...type, isSelected: true } : { ...type, isSelected: false })
    },
  }
})

export const {
  setLoading,
  setPaymentsTable,
  setSpendingsTable,
  setOperationsTable,
  setSearchBody,
  setSelectedReport
} = reportsSlice.actions

export default reportsSlice.reducer;

//axios
const payments = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('reports'));
    const res = await reportsApi.payments(params)
    dispatch(setPaymentsTable(res));
    dispatch(setLoading('reports'));

  } catch (err) {
    dispatch(setLoading('reports'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const spending = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('reports'));
    const res = await reportsApi.spending(params)
    dispatch(setSpendingsTable(res));
    dispatch(setLoading('reports'));

  } catch (err) {
    dispatch(setLoading('reports'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};
const operation = (params) => async (dispatch) => {
  try {
    dispatch(setLoading('reports'));
    const res = await reportsApi.operation(params)
    dispatch(setOperationsTable(res));
    dispatch(setLoading('reports'));

  } catch (err) {
    dispatch(setLoading('reports'));
    dispatch(showNotification({
      message: err.response.data.errors[0],
      type: "error"
    }));
    throw new Error(err);
  }
};

export const Reports = {
  payments,
  spending,
  operation
}