import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiFillMinusSquare, AiFillPlusSquare, AiOutlineApartment } from "react-icons/ai";
import { setStockMovementDialog } from "../../../../../app/slices/stockMovementSlice";
import StockMovementForm from "../../form";
import { useEffect, useState } from "react";

const StockMovementsHeader = () => {
  const _StockMovement = useSelector((state) => state.stockMovement.stockMovement);
  const _StockMovements = useSelector((state) => state.stockMovement.stockMovements);

  const [isIn, setIsIn] = useState(false)

  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction='column' justifyContent='space-between' width='75%' >
            <Typography variant="h4" component="div">
              حركات المخزن
            </Typography>
            <Stack spacing={1} direction='row'>
              <Typography variant="caption" component="div">
                اسم المادة:
              </Typography>
              <Typography color='red' variant="caption" component="div">
                {_StockMovements.item_name}
              </Typography>
            </Stack>
          </Stack>
        }
        avatar={<AiOutlineApartment size={20} />}
        action={
          <Stack direction='row' spacing={2}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<AiFillPlusSquare />}
              onClick={() => {
                dispatch(setStockMovementDialog(_StockMovements.stock_id));
                setIsIn(true)
              }}
            >
              إضافة للمخزن
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<AiFillMinusSquare />}
              onClick={() => {
                dispatch(setStockMovementDialog(_StockMovements.stock_id));
                setIsIn(false)
              }}            >
              سحب من المخزن
            </Button>
          </Stack>
        }
      />
      {_StockMovement.dialog && <StockMovementForm isIn={isIn} />}
    </Card>
  );
};

export default StockMovementsHeader;
