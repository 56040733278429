import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../app/slices/appointmentSlice";
import { MdOutlineMeetingRoom } from "react-icons/md";
import AppointmentForm from "../../form";

const AppointmentHeader = () => {
  const _Appointment = useSelector((state) => state.appointment.appointment);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المواعيد
          </Typography>
        }
        avatar={<MdOutlineMeetingRoom size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة موعد
          </Button>
        }
      />
      {_Appointment.dialog ? <AppointmentForm /> : null}
    </Card>
  );
};

export default AppointmentHeader;
