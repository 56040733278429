import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Stock,
} from "../../../../app/slices/stockSlice";
import { Controller, useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StockForm = () => {
  const _Stock = useSelector((state) => state.stock.stock);
  const _SearchBody = useSelector((state) => state.stock.searchBody);

  const isAdd = _Stock.form.id ? false : true;
  const { control, reset, handleSubmit} = useForm({
    defaultValues: _Stock.form,
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(Stock.create(data));
    } else {
      dispatch(Stock.update(data.id, data, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._Stock.form });
  }, [reset,_Stock.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);


  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Stock.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label=" الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            {isAdd && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="count"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      type="number"
                      size="small"
                      label="العدد"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الوصف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default StockForm;
