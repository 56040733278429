import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Operation,
  setSearchBody,
} from "../../../../app/slices/operationSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaReceipt } from "react-icons/fa";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { SelectCaseType } from "../../../../components/Selectors/SelectCaseType";
import { setOperationPaymentDialog } from "../../../../app/slices/paymentsSlice";
import OperationPayments from "../payments";
import GenerateReceipt from "../../receipt";

const OperationTable = () => {
  const _Operation = useSelector((state) => state.operation.operations);
  const _SearchBody = useSelector((state) => state.operation.searchBody);
  const loading = useSelector((state) => state.operation.loading);
  const _CaseType = useSelector((state) => state.caseType.caseTypes.data);
  const _OperationPayment = useSelector(
    (state) => state.payment.operationPayment.dialog
  );
  const _OperationReceipt = useSelector(
    (state) => state.operation.receipt.dialog
  );

  const [search, setSearch] = useState({
    search: "",
    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
    case_type_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Operation.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "رقم الجلسة",
      flex: 0.5,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography>{params.row.patient_name}</Typography>
          <Typography>{params.row.patient_mobile_1}</Typography>
        </Stack>
      ),
    },
    {
      field: "case_type_name",
      headerName: "نوع الجلسة",
      flex: 1,
    },
    {
      field: "appointment",
      headerName: "رقم الموعد",
      flex: 0.6,
      renderCell: (params) => <>{params.row.appointment?.id}</>,
    },
    {
      field: "amount",
      headerName: "المبلغ الكلي (IQD)",
      flex: 1,
      renderCell: (params) => (
        <>{parseInt(params.row.amount)?.toLocaleString()}</>
      ),
    },
    {
      field: "paid",
      headerName: "المبلغ المدفوع (IQD)",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography color="green">{parseInt(params.row.paid)?.toLocaleString()}</Typography>
          <Typography color="error">{parseInt(params.row.remaining)?.toLocaleString()}</Typography>
        </Stack>
      ),
    },
    // {
    //   field: "remaining",
    //   headerName: "المبلغ المتبقي (IQD)",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <>{parseInt(params.row.remaining)?.toLocaleString()}</>
    //   )
    // },
    {
      field: "discount",
      headerName: "الخصم (IQD)",
      flex: 0.5,
      renderCell: (params) => (
        <>{parseInt(params.row.discount)?.toLocaleString()}</>
      ),
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <>{dayjs(params.row.date).format("YYYY-MM-DD / hh:mm a")}</>
      ),
    },
    // {
    //   field: "user",
    //   headerName: "الاسم المستخدم",
    //   flex: 1,
    // },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(Operation.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>

            <IconButton
              color="success"
              onClick={() => dispatch(setOperationPaymentDialog(params.row.id))}
            >
              <AiFillDollarCircle />
            </IconButton>

            <IconButton
              color="info"
              onClick={() =>
                dispatch(Operation.getByIdForReceipt(params.row.id))
              }
            >
              <FaReceipt />
            </IconButton>

            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Operation.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch,_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        start_date: search.start_date,
        end_date: search.end_date,
        // patient_id: search.patient_id ?? "",
        case_type_id: search.case_type_id ?? "",
        search: search.search,
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch,search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_OperationPayment && <OperationPayments />}
          {_OperationReceipt && <GenerateReceipt />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.user}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="اسم او رقم المراجع"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectCaseType
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  case_type_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _CaseType?.filter((x) => x.id === search.case_type_id)[0]
                  ? _CaseType?.filter((x) => x.id === search.case_type_id)[0]
                  : null
              }
            />
          </Grid>
          {/* <Grid item xs={12} md={2}>
            <SelectPatient
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  patient_id: newValue ? newValue.id : null
                });
              }}
              value={
                _Patient?.filter((x) => x.id == search.patient_id)[0]
                  ? _Patient?.filter(
                    (x) => x.id == search.patient_id
                  )[0]
                  : null
              }
            />
          </Grid> */}
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
                    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
                    // patient_id: null,
                    case_type_id: null,
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Operation.data ? (
                <DataGrid
                  rows={_Operation.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Operation.total}
                  loading={loading}
                  rowHeight={55}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OperationTable;
