import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperationDialog,
  resetForm,
  Operation,
} from "../../../app/slices/operationSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { SelectUser } from "../../../components/Selectors/SelectUser";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SelectPatient } from "../../../components/Selectors/SelectPatient";
import { SelectCaseType } from "../../../components/Selectors/SelectCaseType";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OperationForm = (props) => {
  const _Operation = useSelector((state) => state.operation.operation);
  const _SearchBody = useSelector((state) => state.operation.searchBody);
  const _Patient = useSelector((state) => state.patient.patients.data);
  const _CaseType = useSelector((state) => state.caseType.caseTypes.data);
  const _user = useSelector((state) => state.user.usersList.data);

  const isAdd = _Operation.form.id ? false : true;
  const { control, reset, watch,  handleSubmit, setValue } = useForm({
    defaultValues: _Operation.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(Operation.create({
        patient_id: props.patientId ?? data.patient_id,
        appointment_id: null,
        case_type_id: data.case_type_id,
        user_id: data.user_id,
        amount: data.amount,
        discount: data.discount ? data.discount : 0,
        paid: data.paid,
        description: data.description,
        date: data.date,
      }, _SearchBody));
    } else {
      dispatch(Operation.update(data.id, {
        id: data.id,
        patient_id: data.patient_id,
        appointment_id: null,
        case_type_id: data.case_type_id,
        user_id: data.user_id,
        amount: data.amount,
        discount: data.discount ? data.discount : 0,
        paid: data.paid,
        description: data.description,
        date: data.date,
      }, _SearchBody));
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    reset({ ..._Operation.form });
  }, [reset,_Operation.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);


  const handleDialog = () => {
    dispatch(setOperationDialog());
  };

  return (
    <Dialog
      open={_Operation.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={'sm'}
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {!props.patientId && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="patient_id"
                  control={control}
                  render={({ field }) => (
                    <SelectPatient
                      {...field}
                      required
                      onChange={(e, newValue) => {
                        setValue("patient_id", newValue ? newValue.id : null);
                      }}
                      value={
                        _Patient?.filter((x) => x.id === field.value)[0]
                          ? _Patient?.filter(
                            (x) => x.id === field.value
                          )[0]
                          : null
                      }
                      search={watch('patient_name')}
                    />
                  )}
                />
              </Grid>
            )}
            {!props.appointmentId & !isAdd ? (
              <Grid item xs={12} md={12}>
                <Controller
                  name="appointment_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      disabled={!isAdd}
                      required
                      size="small"
                      label="رقم الموعد"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={12}>
              <Controller
                name="case_type_id"
                control={control}
                render={({ field }) => (
                  <SelectCaseType
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("case_type_id", newValue ? newValue.id : null);
                      setValue("amount", newValue ? newValue.price : "");
                      setValue("discount", newValue ? newValue.discount : "");
                    }}
                    value={
                      _CaseType?.filter((x) => x.id === field.value)[0]
                        ? _CaseType?.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="user_id"
                control={control}
                render={({ field }) => (
                  <SelectUser
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("user_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _user?.filter((x) => x.id === field.value)[0]
                        ? _user?.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='amount'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='المبلغ'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('amount', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                      helperText={`المبلغ الكلي ${watch('amount') - watch('discount') || 0}`}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='discount'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='الخصم'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('discount', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='paid'
                control={control}
                render={({ field: { onChange, ...renderProps } }) => {
                  return (
                    <NumericFormat
                      customInput={TextField}
                      label='المبلغ المدفوع'
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">IQD</InputAdornment>,
                      }}
                      {...renderProps}
                      onValueChange={target => {
                        onChange();
                        setValue('paid', target.floatValue);
                      }}
                      isNumericString
                      thousandSeparator
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{ ...params.inputProps, placeholder: "يوم/شهر/سنة" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الوصف"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
            {!isAdd && (
              <Grid item xs={12} md={12}>
                <Divider />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OperationForm;
