import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/stockSlice";
import { AiOutlineApartment } from "react-icons/ai";
import StockForm from "../../form";

const StockHeader = () => {
  const _Stock = useSelector((state) => state.stock.stock);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            تفاصيل المخزن
          </Typography>
        }
        avatar={<AiOutlineApartment size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_Stock.dialog ? <StockForm /> : null}
    </Card>
  );
};

export default StockHeader;
