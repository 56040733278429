import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Patient, setSearchBody,
} from "../../../../app/slices/patientSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import dayjs from "dayjs";
import { SelectCity } from "../../../../components/Selectors/SelectCity";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";
import { MdOutlineClear } from "react-icons/md";
import { GiOfficeChair } from "react-icons/gi";
import { setOperationDialog } from "../../../../app/slices/operationSlice";
import OperationForm from "../../../operation/form";

const PatientTable = () => {
  const [search, setSearch] = useState({
    search: "",
    city_id: null
  });
  const [provinceId, setProvinceId] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const _Patient = useSelector((state) => state.patient.patients);
  const _SearchBody = useSelector((state) => state.patient.searchBody);
  const loading = useSelector((state) => state.patient.loading);
  const _Operation = useSelector((state) => state.operation.operation);

  const _Cities = useSelector((state) => state.provinces.cities);
  const _provinces = useSelector((state) => state.provinces.provinces);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');
  const [patientId, setPatientId] = useState(false);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(Patient.deleteById(dialogData.id, _SearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "full_name",
      headerName: "الاسم الكامل",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "الجنس",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "رقم الهاتف",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction='column' spacing={0.5}>
            <Typography>
              {params.row.mobile_1}
            </Typography>
            <Typography>
              {params.row.mobile_2}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "city",
      headerName: "المدينة",
      flex: 1,
    },
    {
      field: "birth_date",
      headerName: "تاريخ الميلاد",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {dayjs(params.row.birth_date).format("YYYY-MM-DD")}
          </>
        );
      },
    },
    {
      field: "operation_count",
      headerName: "عدد الجلسات",
      flex: 1,
      renderCell: (params) => params.row.operation_count?.toLocaleString()
    },
    {
      field: "debt",
      headerName: "الديون (IQD)",
      flex: 1,
      renderCell: (params) => params.row.debt?.toLocaleString()
    },
    {
      field: "how_know_us",
      headerName: "كيف سمعت عنا",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(setOperationDialog(params.row.id));
                setPatientId(params.row.id)
              }}
            >
              <GiOfficeChair />
            </IconButton >

            <IconButton
              color="primary"
              onClick={() => dispatch(Patient.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>

            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Patient.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch,_SearchBody]);

  useEffect(() => {
    dispatch(setSearchBody({
      search: search.search,
      city_id: search.city_id ?? "",
      skip: pageSize * currentPage,
      take: pageSize
    }))
  }, [dispatch,search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_Operation.dialog && <OperationForm patientId={patientId} />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.full_name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value
                });
              }}
              value={search.search}
              label="بحث عن الاسم او رقم هاتف"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectProvince
              povinceid={provinceId}
              onChange={(e, newValue) => {
                setProvinceId(newValue ? newValue.id : null)
              }}
              value={
                _provinces.data?.filter((x) => x.id === provinceId)[0]
                  ? _provinces.data?.filter(
                    (x) => x.id === provinceId
                  )[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectCity
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  city_id: newValue ? newValue.id : null
                });
              }}
              value={
                _Cities.data?.filter((x) => x.id === search.city_id)[0]
                  ? _Cities.data?.filter(
                    (x) => x.id === search.city_id
                  )[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    city_id: null
                  });
                  setProvinceId(null)
                  setPageSize(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Patient.data ? (
                <DataGrid
                  rows={_Patient.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Patient.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    setCurrentPage(newPage)
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PatientTable;
